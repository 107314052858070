var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-info-box"
  }, [_vm._m(0), _c('div', {
    staticClass: "sygni-info-box-text"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-info-box-icon"
  }, [_c('span', [_vm._v("i")])]);
}]

export { render, staticRenderFns }